import React from "react";
import { stackList } from "../../data/ProjectData";
import {
  Image,
  Technologies,
  Tech,
  TechImg,
  TechName,
  ContactWrapper,
} from "./AboutElements";
import ScrollAnimation from "react-animate-on-scroll";
function About() {
  return (
    <ContactWrapper id="about">
      <div className="Container">
        <div className="SectionTitle">About Me</div>
        <div className="BigCard">
          <ScrollAnimation animateIn="fadeInLeft">
            <Image
              src="smallphoto.JPEG"

              alt="man-svgrepo"
            />
          </ScrollAnimation>
          <div className="AboutBio">
            <ScrollAnimation animateIn="fadeInLeft">
              <p>
                Hello! My name is <strong>Sebastian Gonzalez</strong>.  I am a Software Engineer and Computer Scientist with over eight years of experience across multiple industries, including finance, healthcare, education, and property technology. I specialize in<b> leading teams to success </b> by aligning stakeholder needs with <b>strategic business development </b>, best technical practices, and cutting-edge technologies to ensure my clients' <b> visions are fully realized </b>.
              </p>
            </ScrollAnimation>

            <br /><br />

            <ScrollAnimation animateIn="fadeInLeft">
              <p>
              In recent years, I have worked as a Lead Software Engineer & Consultant at EPAM and, more recently, at Globant, where I have served as a Senior Software Designer for Globant's biggest client. My role has encompassed the<b> design, planning, and execution </b> of business solutions, leveraging strong technical expertise and leadership skills. Additionally, as a Discipline Lead, I have been responsible for managing various programs that require both technical proficiency and strong interpersonal skills. I <b>actively mentor and support </b> a team of over 10 engineers, sharing knowledge, fostering discussions, and cultivating a growth-oriented engineering mindset by<b> identifying strengths and areas for improvement </b>.
              </p>
            </ScrollAnimation>

            <br /><br />

            <ScrollAnimation animateIn="fadeInLeft">
              <p>
              Beyond my corporate experience, I run my own technology consulting business, where my team and I provide software engineering services to various companies. Our offerings include feature development, technical audits, security assessments, and data modeling, among other services. We are <b>passionate</b> about what we do and <b>committed</b> to delivering high-quality solutions.
              </p>

              {/* <hr /> */}
              <div className="tagline2">

              I have extensive experience with the following technologies:
              </div>
            </ScrollAnimation>


            <Technologies>
              {stackList.map((stack, index) => (
                <ScrollAnimation animateIn="fadeInLeft" key={index}>
                  <Tech key={index} className="tech">
                    <TechImg src={stack.img} alt={stack.name} />
                    <TechName>{stack.name}</TechName>
                  </Tech>
                </ScrollAnimation>
              ))}
            </Technologies>
          </div>

        </div>
      </div>
    </ContactWrapper>
  );
}

export default About;
